.aui-rotate-0 {
  transform: rotate(0deg);
}

.aui-rotate-45 {
  transform: rotate(45deg);
}

.aui-rotate-90 {
  transform: rotate(90deg);
}

.aui-rotate-180 {
  transform: rotate(180deg);
}

.aui-rotate-270 {
  transform: rotate(270deg);
}
