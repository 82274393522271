.aui-markdown-content ul li::before,
.aui-markdown-content ol li::before {
  content: none;
}

.aui-markdown-content h1 {
  font-size: 2rem;
  font-weight: bold;
  margin: 1.33rem 0;
}

.aui-markdown-content h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.25rem 0;
}

.aui-markdown-content h3 {
  font-size: 1.17rem;
  font-weight: bold;
  margin: 1.2rem 0;
}

.aui-markdown-content h4 {
  font-size: 1rem;
  font-weight: bold;
  margin: 1.33rem 0;
}

.aui-markdown-content h5 {
  font-size: 0.83rem;
  font-weight: bold;
  margin: 1.4rem 0;
}

.aui-markdown-content h6 {
  font-size: 0.67rem;
  font-weight: bold;
  margin: 1.55rem 0;
}

.aui-markdown-content p {
  margin: 1rem 0;
}

.aui-markdown-content ul,
.aui-markdown-content ol {
  margin: 1rem 0;
  padding-left: 40px;
}

.aui-markdown-content ul ul,
.aui-markdown-content ol ol {
  margin: 0;
}

.aui-markdown-content ul {
  list-style-type: disc;
}

.aui-markdown-content ul ul {
  list-style-type: circle;
}

.aui-markdown-content ul ul ul {
  list-style-type: square;
}

.aui-markdown-content ol {
  list-style-type: decimal;
}

.aui-markdown-content ol,
.aui-markdown-content ol ol,
.aui-markdown-content ol ol ol {
  list-style-type: none;
  counter-reset: item2;
}

.aui-markdown-content ol li:before {
  counter-increment: item2;
  content: counter(item2, decimal) ". ";
}

.aui-markdown-content ol ol li:before {
  counter-increment: item2;
  content: counter(item2, lower-latin) ". ";
}

.aui-markdown-content ol ol ol li:before {
  counter-increment: item2;
  content: counter(item2, lower-roman) ". ";
}

.aui-markdown-content ul li {
  list-style-type: disc !important;
}

.aui-markdown-content ul ul li {
  list-style-type: circle !important;
}

.aui-markdown-content ul ul ul li {
  list-style-type: square !important;
}
