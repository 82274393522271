.aui-tooltip:hover .aui-tooltip-text {
  visibility: visible;
}

.aui-border-arrow-down::after {
  border-color: #3d4852 transparent transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: "";
  margin-left: -5px;
  left: 49%;
  position: absolute;
  top: 100%;
}

.aui-border-arrow-down-left::after {
  border-color: #3d4852 transparent transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: "";
  margin-left: -5px;
  left: 10%;
  position: absolute;
  top: 100%;
}

.aui-border-arrow-down-right::after {
  border-color: #3d4852 transparent transparent transparent;
  border-style: solid;
  border-width: 5px;
  content: "";
  margin-left: -5px;
  left: 90%;
  position: absolute;
  top: 100%;
}

.aui-border-arrow-up::after {
  border-color: transparent transparent #3d4852 transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 49%;
  position: absolute;
  top: -10px;
}

.aui-border-arrow-up-left::after {
  border-color: transparent transparent #3d4852 transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 10%;
  position: absolute;
  top: -10px;
}

.aui-border-arrow-up-right::after {
  border-color: transparent transparent #3d4852 transparent;
  border-style: solid;
  border-width: 5px;
  content: '';
  left: 90%;
  position: absolute;
  top: -10px;
}

.aui-scrollbar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.aui-scrollbar::-webkit-scrollbar-thumb {
  background: #a2acb0;
  border-radius: 4px;
}
