.aui-text-shadow-sm {
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
}

.aui-text-tiny {
  font-size: 8px;
}

.aui-text-highlight {
  display: inline;
  padding-bottom: 0.1em;
  box-shadow: inset 0 -0.4em #fff9c2;
}

.aui-text-highlight-blue {
  display: inline;
  padding-bottom: 0.1em;
  box-shadow: inset 0 -0.4em #eaf2ff;
}
