.aui-module-shadow {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #e7e8ea;
}

.aui-downwards-shadow {
  border-radius: 2px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
}

.aui-bottom-shadow {
  border-radius: 2px;
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.22);
}

.aui-top-shadow {
  border-radius: 2px;
  box-shadow: 0px -15px 10px -15px rgba(0, 0, 0, 0.22);
}

.aui-left-shadow {
  border-radius: 2px;
  box-shadow: -15px 0px 10px -15px rgba(0, 0, 0, 0.22);
}

.aui-right-shadow {
  border-radius: 2px;
  box-shadow: 15px 0px 10px -15px rgba(0, 0, 0, 0.22);
}

.aui-grid-card-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
