.aui-animate-grow {
  transition: all .2s ease-in-out;
}

.aui-animate-grow:hover {
  transform: scale(1.07);
}
.aui-zoom-in {
  -webkit-animation-name: zoom-in;
  animation-name: zoom-in;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.aui-zoom-out {
  -webkit-animation-name: zoom-out;
  animation-name: zoom-out;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.aui-slide-in-left {
  -webkit-animation: slide-in-left .5s forwards;
  animation: slide-in-left .5s forwards;
  left: -40%;
}

.aui-slide-in-left-under-flyout {
  -webkit-animation: slide-in-horizontal-under-flyout .5s forwards;
  animation: slide-in-horizontal-under-flyout .5s forwards;
  transform: translateX(-100%);
  z-index: 0;
}

.aui-slide-in-right {
  -webkit-animation: slide-in-right .5s forwards;
  animation: slide-in-right .5s forwards;
  right: -40%;
}

.aui-slide-in-right-under-flyout {
  -webkit-animation: slide-in-horizontal-under-flyout .5s forwards;
  animation: slide-in-horizontal-under-flyout .5s forwards;
  transform: translateX(100%);
  z-index: 0;
}

.aui-slide-in-bottom {
  -webkit-animation: slide-in-bottom .5s forwards;
  animation: slide-in-bottom .5s forwards;
  bottom: -40%;
}

.aui-slide-in-bottom-under-flyout {
  -webkit-animation: slide-in-vertical-under-flyout .5s forwards;
  animation: slide-in-vertical-under-flyout .5s forwards;
  transform: translateY(100%);
  z-index: 0;
}

.aui-slide-out-bottom {
  -webkit-animation: slide-out-bottom .5s forwards;
  animation: slide-out-bottom .5s forwards;
  bottom: 0;
}

.aui-slide-out-bottom-under-flyout {
  -webkit-animation: slide-out-bottom-under-flyout .5s forwards;
  animation: slide-out-bottom-under-flyout .5s forwards;
  transform: translateY(0);
}

.aui-slide-in-top {
  -webkit-animation: slide-in-top .5s forwards;
  animation: slide-in-top .5s forwards;
  top: -40%;
}

.aui-slide-in-top-under-flyout {
  -webkit-animation: slide-in-vertical-under-flyout .5s forwards;
  animation: slide-in-vertical-under-flyout .5s forwards;
  transform: translateY(-100%);
  z-index: 0;
}

.aui-slide-out-top {
  -webkit-animation: slide-out-top .5s forwards;
  animation: slide-out-top .5s forwards;
  top: 0;
}

.aui-slide-out-top-under-flyout {
  -webkit-animation: slide-out-top-under-flyout .5s forwards;
  animation: slide-out-top-under-flyout .5s forwards;
  transform: translateY(0);
}

.aui-slide-out-left {
  -webkit-animation: slide-out-left 1.5s forwards;
  animation: slide-out-left 1.5s forwards;
  left: 0;
}

.aui-slide-out-left-under-flyout {
  -webkit-animation: slide-out-left-under-flyout .5s forwards;
  animation: slide-out-left-under-flyout .5s forwards;
  transform: translateX(0);
}

.aui-fade-in {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fade-in;
}

.aui-fade-out {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fade-out;
}

.aui-slide-out-right {
  -webkit-animation: slide-out-right 1.5s forwards;
  animation: slide-out-right 1.5s forwards;
  right: 0;
}

.aui-slide-out-right-under-flyout {
  -webkit-animation: slide-out-right-under-flyout .5s forwards;
  animation: slide-out-right-under-flyout .5s forwards;
  transform: translateX(0);
}

.aui-blur-in-small {
  animation-duration: 0.5s;
  animation-name: blur-in-small;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: blur-in-small;
}

@-webkit-keyframes blur-in-small {
  0% {
    opacity: 0;
    transform: scale(1.5, 1.5);
    transform-origin: 50% 50%;
    filter: blur(10px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1.5, 1.5);
    -webkit-filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

@keyframes blur-in-small {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(1.5, 1.5);
    filter: blur(10px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1.5, 1.5);
    -webkit-filter: blur(10px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

.aui-blur-in {
  animation-duration: 0.5s;
  animation-name: blur-in;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: blur-in;
}

@-webkit-keyframes blur-in {
  0% {
    opacity: 0;
    transform: scale(2, 2);
    transform-origin: 50% 50%;
    filter: blur(190px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    -webkit-filter: blur(190px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

@keyframes blur-in {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(190px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(2, 2);
    -webkit-filter: blur(190px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
    -webkit-transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    -webkit-filter: blur(0px);
  }
}

@-webkit-keyframes zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoom-out {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-left {
  100% {
    left: 0;
  }
}

@keyframes slide-in-left {
  100% {
    left: 0;
  }
}

@-webkit-keyframes slide-in-horizontal-under-flyout {
  99% {
    z-index: 0;
  }
  100% {
    transform: translateX(0);
    z-index: 50;
  }
}

@keyframes slide-in-horizontal-under-flyout {
  99% {
    z-index: 0;
  }
  100% {
    transform: translateX(0);
    z-index: 50;
  }
}

@-webkit-keyframes slide-out-left {
  100% {
    left: -100%;
  }
}

@keyframes slide-out-left {
  100% {
    left: -100%;
  }
}

@-webkit-keyframes slide-out-left-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-out-left-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-in-right {
  100% {
    right: 0;
  }
}

@keyframes slide-in-right {
  100% {
    right: 0;
  }
}

@-webkit-keyframes slide-out-right {
  100% {
    right: -100%;
  }
}

@keyframes slide-out-right {
  100% {
    right: -100%;
  }
}

@-webkit-keyframes slide-out-right-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slide-out-right-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-in-bottom {
  100% {
    bottom: 0;
  }
}

@keyframes slide-in-bottom {
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes slide-in-vertical-under-flyout {
  99% {
    z-index: 0;
  }
  100% {
    transform: translateY(0);
    z-index: 50;
  }
}

@keyframes slide-in-vertical-under-flyout {
  99% {
    z-index: 0;
  }
  100% {
    transform: translateY(0);
    z-index: 50;
  }
}

@-webkit-keyframes slide-out-bottom {
  100% {
    bottom: -100%;
  }
}

@keyframes slide-out-bottom {
  100% {
    bottom: -100%;
  }
}

@-webkit-keyframes slide-out-bottom-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes slide-out-bottom-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateY(100%);
  }
}

@-webkit-keyframes slide-in-top {
  100% {
    top: 0;
  }
}

@keyframes slide-in-top {
  100% {
    top: 0;
  }
}

@-webkit-keyframes slide-out-top {
  100% {
    top: -100%;
  }
}

@keyframes slide-out-top {
  100% {
    top: -100%;
  }
}

@-webkit-keyframes slide-out-top-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slide-out-top-under-flyout {
  1% {
    z-index: 0;
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    height: 0;
    opacity: 0;
    padding: 0;
  }
}
